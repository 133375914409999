.portfolio-1col-boxed { 
  .portfolio-item {
    height: 355px;
    figcaption {
      padding: 8px 70px;
      .subheading {
        margin-bottom: 20px;
      }
      .view-btn {
        margin-top: 25px;
      }
    }
  }
}