.flex-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.flex-column {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  flex-direction: column;
}

.justify-content-center {
  justify-content: center;
} 

.align-items-flex-start {
  align-items: flex-start;
}

@media (min-width: 992px) {

  .justify-content-md-center {
    -ms-flex-pack: center!important;
    justify-content: center!important;
  }

  .flex-col-md-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .flex-col-md-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

}

.col {
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
  width: 100%;
}

@media (min-width: 992px) {

  .justify-content-md-center {
    -ms-flex-pack: center!important;
    justify-content: center!important;
  }

  .flex-col-md-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .flex-col-md-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

}