.fw-slider-hero {
  .fw-slide-2 {
    background: url('/assets/images/hero/engagment_hero_2.jpg');
    background-position: 45%, 35%;
  }
}

.kenburn-hero {
  .kenburn-slider {
    .hero-wrapper {
      padding-top: 72px;
      padding: 72px 2rem 0px 2rem;
    }
  }
} 