@import "navigation";
@import "hero";
@import "counters";
@import "images";
@import "portfolio";
@import "flex";

.card {
  box-shadow: 0 1px 1px rgba(0,0,0,.2);
  transition: all .3s;
  background: #f4f4f4;

  .card-body {
    padding: 20px;
  }

  @media (min-width: 992px) {
    .card-body { 
      padding: 0px 70px;
    }
  }

}

.address-group {
  word-wrap: break-word;
}

@media (max-width: 992px) {
 .section {
   padding-left: 25px;
   padding-right: 25px;
 }
}