.navbar-trans {
  .navbar-logo {
   content: url('/assets/images/Hills-Horizontal-Inverted@2x.png');
  }
}

.navbar-static-top {
  transition: all 0.2s cubic-bezier(.02, .01, .47, 1);
  background-color: white;
  top: 72px;
  margin-top: -72px;
  &.navbar-transparent {
    border-bottom: none;
    background-color: transparent;
    .navbar-logo {
     content: url('/assets/images/Hills-Horizontal-Inverted@2x.png');
    }
  }
}

.navbar-button {
  position: relative;
  float: right;
  padding: 9px 10px;
  background-color: transparent;
  background-image: none;
  border: none;
  margin: 2rem 0;
  .icon-bar{
    display: block;
    width: 22px;
    height: 2px;
    border-radius: 1px;
    background: #999;
    transition: background .3s;
    &+.icon-bar{
      margin-top: 4px;
    }
  }
  &:focus{
    outline: none;
  }
}

.mobile-sub-nav {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 42px;
  ul {
    margin: 0;
    padding: 0;
    li {
      list-style-type: none;
      a {
        text-transform: uppercase;
        font-size: .8em;
        font-weight: 400;
        letter-spacing: 2px;
        color: #999;
        transition: color .5s;
        padding-left: 30px;
        padding-right: 30px;
      }
    }
  }
  .icon {
    font-size: 28px;
    margin-right: 1rem;
    &::before {
      padding-top: 4px;
      display: inline-block;
    }
  }
}

.locked {
  overflow: hidden;
}

.mobile-menu {
  position: absolute;
  top: 0px;
  left: 0px;
  margin-top: 72px;
  padding: 2rem 2rem 0 2rem;
  width: 100%;
  background: white;
  text-align: left;
  z-index: 1;

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    list-style-type: none;
    &.inline {
      display: inline-block;
      padding: 0 0.5rem;
    }
  }

  hr {
    width: 100%;
    max-width: 320px;
    display: inline-block;
  }

  .main-links {
    li {
      height: 48px;
    }
    a{
      font-size: 19px;
    }
  }

  .name {
    margin: 0.5rem 0 0 0;
  }

  .close {
    position: absolute;
    top: -55px;
    right: 20px;
    opacity: 0.75;
    i {
      font-size: 42px;
    }
  }

  .auth-in {
    margin-top: 2.25rem;
  }
}

.navbar {
  min-height: 72px;
  padding-left: 2rem;
  padding-right: 2rem;

  .navbar-header{
    margin-top: 0px;
    width: auto;
    
    .navbar-brand{
      padding: 12px 0px;
      height: 72px;
    }
  }

  .navbar-logo {
    width: 168px;
    height: 48px;
  }  
}

.navbar-flex {
  display: flex;
  transition: all 0.2s cubic-bezier(.02, .01, .47, 1);
  &.fullwidth {
    width: 100%;
  }
  &.navbar-center {
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .navbar-row {
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-top: 0;
      li a {
        padding-left: 30px;
        padding-right: 30px;
      }
    }
  }
}

@media (max-width: 1260px) {

  .navbar.navbar-flex {
    padding: 0;
    &.subnav {
      min-height: 40px;
    }
    &.navbar-center {
      .navbar-row {
        width: 100%;
        margin-bottom: 0;
        li a {
          padding-left: 30px;
          padding-right: 30px;
        }
      }
    }
  }

  .submenu {
    display: none;
  }

}

@media (max-width: 600px) {

  .navbar {
    .navbar-header {
      
      .navbar-brand {
        margin-left: 0px;
      }

      .navbar-toggle{
        margin-top: 1rem;
      }
    }
  }
  nav:not(.navbar-small) {
    .navbar-header {
      .navbar-toggle{
        margin: 2rem 0;
      }
    }
  }
}
